

export default {
  SET_ORDERS(state, orders) {
    state.orders = orders
  },
  SET_ORDER(state, order){
  	state.order = order
    if(state.orders.length){
      const dataIndex = state.orders.findIndex((o) => o.id == order.id);
      Object.assign(state.orders[dataIndex], order);
    }
  },
  REMOVE_ORDER(state, orderId){
	const ItemIndex = state.orders.findIndex((item) => item.id == orderId)
    state.orders.splice(ItemIndex, 1)
  },

  /**************** THIS IS FOR DELIVERIES********************/
  SET_ORDER_DELIVERIES(state, deliveries){
    state.order.deliveries = [...deliveries]
  },
  ADD_DELIVERY(state, delivery){
    state.order.deliveries.unshift(delivery)
  },
  UPDATE_DELIVERY(state, delivery){
    const dataIndex = state.order.deliveries.findIndex((a) => a.id == delivery.id);
    Object.assign(state.order.deliveries[dataIndex], delivery);
  },
  DELETE_DELIVERY(state, deliveryId){
    const itemIndex = state.order.deliveries.findIndex((item) => item.id == deliveryId)
      state.order.deliveries.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR MESSAGES********************/
  SET_ORDER_MESSAGES(state, messages){
    state.order.messages = [...messages]
  },
  ADD_MESSAGE(state, message){
    state.order.messages.push(message)
  },
  UPDATE_MESSAGE(state, message){
    const dataIndex = state.order.messages.findIndex((a) => a.id == message.id);
    Object.assign(state.order.messages[dataIndex], message);
  },
  DELETE_MESSAGE(state, messageId){
    const itemIndex = state.order.messages.findIndex((item) => item.id == messageId)
      state.order.messages.splice(itemIndex, 1)
  },
}
