// axios
import axios from 'axios'
import env from './env.js'

const domain = ""

const instance = axios.create({
  domain,
  baseURL: `${env.domain}/api/buyer/`,
  headers: {'X-Requested-With': 'XMLHttpRequest'}
});


instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// instance.defaults.headers.common['Authorization'] =  `Bearer ${userData.token}`;
export default instance
