

<template>
    <ul class="list">
        <li class="list__item inline-block" v-for="(file, index) in list" :key="index">
            <a :href="`${$store.state.domain}/download?file_path=${file.path}`" :download="file.name" style="color:inherit;" class="downloadable text-sm">
                <div>
                    <img class="responsive" v-if="isImageFile(file.extension)" :src="$store.state.domain+`/${file.path}`" :alt="file.name">
                    <h6 style="color: inherit;" class="flex" v-else >
                        <span><img width="12" :src="$store.state.domain+`/images/file-ext-icons/${file.extension}.png`"></span>
                        <span>{{file.name}}</span></h6>
                </div>
                <div style="font-size: 10px;">
                    <span class="mr-2">{{file.name | truncate(30)}} ({{file.size}}) </span>
                </div>
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "file-display",
    props: {
        list: {
            type: Array,
            required: true,
        },
    }
}
</script>
