
export default {
	getBuyerOrders: (state) => (id) => {
      return  JSON.parse(JSON.stringify(state.orders)).filter((order) => (order.user_id === id));
  },
  getProductOrders: (state) => (id) => {
    return  JSON.parse(JSON.stringify(state.orders)).filter((order) => (order.product_id == id));
  },
  totalOrders: (state) => state.orders.length,

}
