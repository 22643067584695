
import axios from "@/axios.js"

export default {
  fetchOrders({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/orders")
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDERS', response.data.orders)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/orders/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_ORDER', response.data.order)
           }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchDeliveries({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-deliveries/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_DELIVERIES', response.data.deliveries)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchMessages({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.get(`/order-messages/${orderId}/show`)
        .then((response) => {
          if(response.data.success){
            commit('SET_ORDER_MESSAGES', response.data.messages)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  removeOrder({ commit }, orderId) {
    return new Promise((resolve, reject) => {
      axios.delete(`/orders/${orderId}/delete`)
        .then((response) => {
          if(response.data.success){
            commit('REMOVE_ORDER', orderId)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  multipleAction({ commit }, payload) {
    return new Promise((resolve, reject) => {
      axios.post(payload.action,payload.formData)
        .then((response) => {
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}
