export default {
  computed: {
    getStatusColor(){
      return (status) => status ? "primary" : "warning"
    },
  },
  methods: {
    dropzoneTemplate: function () {
      return ` <div class="dropzone-previews">
        <div class="vx-card mt-1 mb-0 shadow-none border">
            <div class="p-2">
                <div class="vx-row">
                    <div class="vx-col w-2/3">
                        <img data-dz-thumbnail src="#" width="100%" class="rounded bg-light" alt="">
                    </div>
                    <div class="vx-col text-right w-1/3">
                        <!-- Button -->
                        <a href="#" class="btn text-danger btn-link btn-lg text-muted" data-dz-remove>
                            X
                        </a>
                    </div>
                </div>
                <div>
                    <div class="w-full">
                        <a href="javascript:void(0);" style="word-break: break-all;" class="text-muted font-weight-bold" data-dz-name></a>
                        <p class="mb-0" data-dz-size></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    `;
    },
    isImageFile(ext){
      return (ext === 'jpeg' || ext === 'png' || ext === 'jpg' || ext === 'svg')
    },
    alertSuccess(text){
      this.$vs.notify({
      title:'Success',
      text:text,
      color:'success',
      iconPack: 'feather',
      icon:'icon-check',
      position:'top-right'})
    },
    alertError(text){
      this.$vs.notify({
      title:'Error',
      text:text,
      color:'warning',
      iconPack: 'feather',
      icon:'icon-alert-circle',
      position:'top-right'})
    },
  }
};
